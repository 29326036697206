var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('special-gift-card', {
    attrs: {
      "special-card-type": _vm.GiftCardTypes.STAFF_CARD
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }